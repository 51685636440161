<p-card>
    <div class="p-grid">
        <div class="p-col-12 p-field p-mb-0 p-mt-3">
            <label for="name">{{'campaign.entity_fields.name' | translate}}</label>
            <input name="name" type="text" pInputText [(ngModel)]="filters.name" (ngModelChange)="onInputChange()"
                (keydown.enter)="loadCampaigns()">
        </div>
    </div>
</p-card>
<div style="margin: 8px;"></div>
<p-card style="margin-top: 8px;">
    <div class="p-grid p-grid-nogutter p-mt-2">
        <label for="name"> {{'component.campaigns.detail.campaigns_to_add' | translate}}</label>
        <p-table #grid [lazy]="true" [value]="campaigns" [reorderableColumns]="false" styleClass="p-datatable-sm"
            [scrollable]="true" scrollHeight="150px" class="p-mt-3" style="width: 100%;">
            <ng-template pTemplate="header">
                <tr>
                    <th></th>
                    <th>{{'campaign.entity_fields.id' | translate}}</th>
                    <th>{{'campaign.entity_fields.name' | translate}}</th>
                    <th>{{'campaign.entity_fields.from' | translate}}</th>
                    <th>{{'campaign.entity_fields.to' | translate}}</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item let-index="rowIndex" let-rowData>
                <tr [pReorderableRow]="index">
                    <td width="25"><p-checkbox name="groupname" value="{{item.id}}" [inputId]="item.id"
                            [(ngModel)]="selected"></p-checkbox></td>
                    <td>{{rowData.id}}</td>
                    <td>{{rowData.name}}</td>
                    <td>{{rowData.from | date}}</td>
                    <td>{{rowData.to | date}}</td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td [attr.colspan]="5">
                        {{'general.not_data' | translate }}
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="p-d-flex p-mt-3">
        <div class="flex-grow-1"></div>
        <p-button label="{{'general.action.cancel' | translate}}" icon="pi pi-times"
            styleClass="p-button-secondary p-button-text" (click)="cancel()"></p-button>
        <p-button label="{{'component.reports.detail.save_campaign' | translate}}" icon="pi pi-check"
            [disabled]="id == null" styleClass="p-ml-2" (click)="saveCampaigns()"></p-button>
    </div>
</p-card>