import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FilterService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { EntitiesFieldsService } from 'src/app/configuration/entities-fields.service';
import { FamiliesService } from 'src/app/configuration/families/families.service';
import { TaxRatesService } from 'src/app/configuration/tax-rates/tax-rates.service';
import { CoreDialogService } from 'src/app/core/dialogs/core-dialog.service';
import { ViewComponent } from 'src/app/core/view/view.component';
import { ProductsService } from 'src/app/products/products.service';
import { TransactionsLinesService } from '../transactions-lines.service';
import { LoginService } from '../../../../login/login.service';
import { CustomComponent } from '../../../../core/view/component/custom/custom-component.interface';

@Component({
  selector: 'app-transaction-lines-detail-custom',
  templateUrl: './transaction-lines-detail-custom.component.html',
  styleUrls: ['./transaction-lines-detail-custom.component.scss']
})
export class TransactionLinesDetailCustomComponent implements OnInit, CustomComponent {
  @Input() public view: ViewComponent;
  @Input() public data: any
  @Input() public entity: any;

  public entityBaseName: string = "transacción";
  public transactionLine: any = {};
  public tax_rates: any;
  public params: any = {};
  public families: any[] = [];
  public familiesFiltered: any[] = [];
  public products: any[] = [];
  public productsFiltered: any[] = [];
  public productsNames: any[] = [];
  public productsNamesJson: any[] = [];
  public hasCharacteristics: boolean = false;
  public characteristics1: any[] = [];
  public characteristics2: any[] = [];
  public characteristics1Name: string = "";
  public characteristics2Name: string = "";
  public oldItemId: any;
  public available_fields: any = [];

  constructor(
    private familiesService: FamiliesService,
    private productsService: ProductsService,
    private transactionsLinesService: TransactionsLinesService,
    private taxRatesService: TaxRatesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private coreDialogService: CoreDialogService,
    private dynamicDialogRef: DynamicDialogRef,
    private entitiesFieldsService: EntitiesFieldsService,
    private filterService: FilterService,
    private loginService: LoginService
  ) {
  }

  ngOnInit(): void {

    this.filterService.register('hasAllWords', (value, filter): boolean => {
      let words = filter.toLowerCase().split(' ');
      return words.every(q => value.toLowerCase().includes(q));
      return true;
    });
    this.entityBaseName = this.config.data.entityBaseName;
    this.GetTransactionLinesFieldsAvailable();
    this.loadFamilies();
    this.loadTaxRates();
    this.loadData(this.config.data.id);
    this.GetEntityFieldDescription();

    if (this.loginService.hasFeature(31) || this.loginService.hasFeature(28)) {
      //this.loadZones();
    }
  }

  is_available(code){
    return this.available_fields.includes(code);
  }

  GetTransactionLinesFieldsAvailable() {
    this.entitiesFieldsService.GetTransactionLinesFieldsAvailable().subscribe({
      next: (data: any) => {
        console.log("asdf GetTransactionLinesFieldsAvailable data", data);
        this.available_fields = data.map(field => field.name);
        console.log("asdf this.available_fields -> ", this.available_fields);
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  GetEntityFieldDescription() {
    this.entitiesFieldsService.GetEntityFieldDescription(8, "characteristics1").subscribe({
      next: (data: any) => {
        this.characteristics1Name = data.description;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
    this.entitiesFieldsService.GetEntityFieldDescription(8, "characteristics2").subscribe({
      next: (data: any) => {
        this.characteristics2Name = data.description;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  loadData(id) {
    if (id) {
      this.transactionsLinesService.get(id).subscribe({
        next: (data: any) => {
          this.transactionLine = data;
          this.transactionLine.tax_percent = this.transactionLine.tax_percent + "";
          this.transactionLine.custom_cost_price = (this.transactionLine.cost_price * 1 / (this.transactionLine.qty ?? 1));
          this.params.product_id = this.transactionLine.product_id;
          this.params.product_name = this.transactionLine.product?.name;
          this.params.family_id = this.transactionLine.product?.family_id;
          this.params.characteristics1 = this.transactionLine.product?.characteristics1;
          this.params.characteristics2 = this.transactionLine.product?.characteristics2;
          this.transactionLine.business_id = this.config.data.business_id;
        },
        error: (error: any) => {
          this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
        }
      });
    } else {
      this.transactionLine.transaction_id = this.config.data.transaction_id;
      this.transactionLine.business_id = this.config.data.business_id;
    }
  }

  loadFamilies() {
    this.familiesService.all({}).subscribe({
      next: (data: any) => {
        this.families = data.rows;
        this.loadProducts();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }
  loadProducts() {
    let params: any = {};
    if (this.transactionLine.business_id != null) {
      params = { business_id: this.transactionLine.business_id };
    }
    this.productsService.all(params).subscribe({
      next: (data: any) => {
        this.products = data.rows
        if (data.rows.map(m => m.characteristics1).filter(m => m != null).length > 0 || data.rows.map(m => m.characteristics2).filter(m => m != null).length > 0) {
          this.hasCharacteristics = true;
        }
        this.loadFilters();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }

  onlyUnique(value, index, self) {
    //Realiza la función de un distinct.
    return self.indexOf(value) === index;
  }

  clearFilters() {
    this.params = {};
    this.clearTransactionline();
    this.getOptions();
  }

  clearTransactionline() {
    this.transactionLine.product_id = null;
    this.transactionLine.net_price = null;
    this.transactionLine.tax_percent = null;
    this.transactionLine.price = null;
    this.transactionLine.total_amount = null;
    this.transactionLine.qty = null;
    this.transactionLine.base_amount = null;
    this.transactionLine.description = null;
    this.transactionLine.discount = null;
  }

  onDropDownChange(field: string, event: any, value: any) {
    if (event && value == null) {
      this.params.product_id = null;
      if (field == "family_id") {
        this.params.product_name = null;
        this.params.characteristics1 = null;
        this.params.characteristics2 = null;
      } else if (field == "product_name") {
        this.params.characteristics1 = null;
        this.params.characteristics2 = null;
      }
      else if (field == "characteristics1") {
        this.params.characteristics2 = null;
      }
      this.clearTransactionline();
    }
    this.loadFilters();

  }

  loadFilters() {

    if (this.params.product_id == null) delete this.params.product_id;
    if (this.params.family_id == null) delete this.params.family_id;
    if (this.params.characteristics1 == null || this.params.characteristics1 == "null") delete this.params.characteristics1;
    if (this.params.characteristics2 == null || this.params.characteristics2 == "null") delete this.params.characteristics2;
    if (this.params.product_name == null || this.params.product_name == "null") delete this.params.product_name;

    this.getOptions();
  }

  getOptions() {
    /*this.params._page = 0;
    this.params._pageSize = 100;*/
    this.productsService.getOptions(this.params).subscribe({
      next: (data: any) => {
        var families_ids = data.map(m => m.family_id).filter(this.onlyUnique).filter(m => m != null);
        this.familiesFiltered = this.families.filter(f => families_ids.includes(f.id));

        var products_ids = data.map(m => m.product_id).filter(this.onlyUnique).filter(m => m != null);
        this.productsFiltered = this.products.filter(p => products_ids.includes(p.id));
        this.productsNames = this.productsFiltered.map(m => m.name).filter(this.onlyUnique);
        //Convierto el array de string a array de objetos con valor strings porque el dropdown de primeng no funciona con arrays de string por algún motivo.
        this.productsNamesJson = this.productsNames.map(m => { return { name: m }; });
        this.characteristics1 = data.map(m => m.characteristics1).filter(this.onlyUnique).filter(m => m != null).map(m => { return { label: m, value: m }; });
        this.characteristics2 = data.map(m => m.characteristics2).filter(this.onlyUnique).filter(m => m != null).map(m => { return { label: m, value: m }; });
        //this.characteristics1.push({label: "Ninguna", value: null});
        //this.characteristics2.push({label: "Ninguna", value: null});

        //si solo queda una opcion en el combo la marcamos
        if (this.familiesFiltered.length == 1) this.params.family_id = this.familiesFiltered[0].id;
        if (this.productsNames.length == 1) this.params.product_name = this.productsNames[0];
        //if (this.characteristics1.length == 1) this.params.characteristics1 = this.characteristics1[0].value;
        //if (this.characteristics2.length == 1) this.params.characteristics2 = this.characteristics2[0].value;

        //si solo habia un resultado
        if (data.length == 1) {

          //rellenamos params
          this.params.product_id = data[0].product_id;
          this.params.product_name = data[0].product_name;
          this.params.family_id = data[0].family_id;
          this.params.characteristics1 = data[0].characteristics1;
          this.params.characteristics2 = data[0].characteristics2;

          if (this.transactionLine.qty == null) this.transactionLine.qty = 1;
          if (this.transactionLine.product_id == 0 || this.transactionLine.product_id == null || (this.oldItemId != null && this.transactionLine.product_id != this.oldItemId)) {
            this.transactionLine.product = this.products.find(m => m.id == data[0].product_id);
            this.transactionLine.product_id = data[0].product_id;
            this.transactionLine.tax_rate_id = this.transactionLine.product.tax_rate_id;
            this.transactionLine.net_price = (this.transactionLine.product.net_price == null || this.transactionLine.product.net_price == '' || this.transactionLine.product.net_price == 'null' ? 0 : this.transactionLine.product.net_price);
            this.transactionLine.description = this.transactionLine.product.description;
          }

          if (this.transactionLine.product_id != null) {
            this.view.onEvent.emit({ component: "transactionLine.edit.general", event: "product_selected", view: this.view, entity: this.transactionLine })
          }
          this.calculateTotals();
        }
      },
      error: (error: any) => {
        console.log(error);
      }
    });
  }

  loadTaxRates() {
    this.taxRatesService.all({}).subscribe({
      next: (data: any) => {
        this.tax_rates = data.rows;
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', detail: error.error.title });
      }
    });
  }


  calculateTotals() {
    if (this.transactionLine.tax_rate_id > 0) {
      this.transactionLine.tax_percent = "" + this.tax_rates.find(m => m.id == this.transactionLine.tax_rate_id).percent;
    } else {
      this.transactionLine.tax_percent = "0";
    }
    if (Number.isNaN(this.transactionLine.net_price)) {
      this.transactionLine.net_price = 0;
    }
    if (this.transactionLine.net_price == null || this.transactionLine.net_price == 'null' ||
      this.transactionLine.tax_percent === null || this.transactionLine.qty == null ||
      this.transactionLine.net_price == "" || this.transactionLine.tax_percent == "" || this.transactionLine.qty == "") {
      this.transactionLine.price = null;
      this.transactionLine.base_amount = null;
      this.transactionLine.total_amount = null;
      this.transactionLine.cost_price = null;
    } else {
      var net_price = parseFloat(this.transactionLine.net_price);
      var cost_price = (this.transactionLine.custom_cost_price != null ? parseFloat(this.transactionLine.custom_cost_price) : 0);
      this.transactionLine.base_amount = net_price * this.transactionLine.qty;
      this.transactionLine.cost_price = cost_price * this.transactionLine.qty;
      this.transactionLine.price = net_price + (net_price * this.transactionLine.tax_percent / 100);
      if (this.transactionLine.discount != null) {
        this.transactionLine.base_amount = this.transactionLine.base_amount - (this.transactionLine.base_amount * this.transactionLine.discount / 100);
      }
      this.transactionLine.total_amount = this.transactionLine.base_amount + (this.transactionLine.base_amount * this.transactionLine.tax_percent / 100);
    }
  }

  onEvent(event) {

  }
}
