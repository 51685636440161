import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { NotificationsService } from 'src/app/notifications/notifications.service';
import { MeetingsService } from 'src/app/organizations/organizations-detail/organizations-meetings-detail/meetings.service';
import { TasksService } from 'src/app/tasks/tasks.service';
import { TransactionsService } from 'src/app/transactions/transactions.service';
import { CampaignsContactsService } from '../campaigns-contacts.service';
import { CampaignsOrganizationsService } from '../campaigns-organizations.service';
import { CampaignsService } from '../campaigns.service';

@Component({
  selector: 'app-add-campaigns-to-entity',
  templateUrl: './add-campaigns-to-entity.component.html',
  styleUrls: ['./add-campaigns-to-entity.component.scss']
})
export class AddCampaignsToEntityComponent {
  public campaigns: any[] = [];
  public entity;
  public results: any[] = [];
  public selected: any[] = [];
  public filters: any = {};
  public is_valid: boolean = false;
  public id;

  constructor(
    private campaignsService: CampaignsService,
    private campaignsOrganizationsService: CampaignsOrganizationsService,
    private campaignsContactsService: CampaignsContactsService,
    private tasksService: TasksService,
    private meetingsService: MeetingsService,
    private transactionsService: TransactionsService,
    private notificationsService: NotificationsService,
    private messageService: MessageService,
    private translateService: TranslateService,
    private dynamicDialogRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { }

  ngOnInit() {
    if (this.config.data.organization_id) this.id = this.config.data.organization_id;
    if (this.config.data.contact_id) this.id = this.config.data.contact_id;
    this.entity = this.config.data.entity;
    this.loadCampaigns();
  }

  loadCampaigns() {
    this.campaignsService.all(this.filters).subscribe({
      next: (data: any) => {
        this.campaigns = data.rows;
      },
      error: (error: any) => {

      }
    });
  }
  validateFilters(): boolean {
    for (const key in this.filters) {
      if (this.filters[key].length >= 3) {
        this.is_valid = true;
        return true;
      }
    }
    this.is_valid = false;
    return false;
  }
  onInputChange(): void {
    this.is_valid = this.validateFilters();
  }

  saveCampaigns() {
    switch (this.entity) {
      case "contact":
        this.saveContactCampaigns();
        break;
      case "organization":
        this.saveOrganizationCampaigns();
        break;
      default:
        break;
    }
  }
  saveOrganizationCampaigns() {
    this.campaignsOrganizationsService.addMultiplesCampaignsToOrganization({ organization_id: this.id, ids: this.selected }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_" + this.entity) });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }

  saveContactCampaigns() {
    this.campaignsContactsService.addMultiplesCampaignsToContact({ contact_id: this.id, ids: this.selected }).subscribe({
      next: (data: any) => {
        this.messageService.add({ closable: false, severity: 'success', summary: this.translateService.instant("component.reports.added_elements_to_" + this.entity) });
        this.dynamicDialogRef.close();
      },
      error: (error: any) => {
        this.messageService.add({ closable: false, severity: 'error', summary: 'Error', detail: error.error.title });
      }
    });
  }
  cancel() {
    this.dynamicDialogRef.close();
  }
}